<style lang="less" scoped>
  @import "../../../assets/css/variables";
  .cust-wraper{
    font-size: 14px;
    color: #666666;
    padding:15px;

  }
  .brandImg{
    width: 200px;
    height: 200px;
    margin: 0 auto;
    // background-color: #99a9bf;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .qrticket > img {
    display: inline-block;
    max-width: 200px;
    vertical-align: top;
    margin-left: 15px;
  }
  .companyInfo {
    position: relative;
    width: 500px;
    height: 533px;
    margin: 0 auto;
    background: url('../../../assets/images/detailBg.png') no-repeat center center;
    .com-content{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 240px;
      height: 425px;
      overflow: hidden;
      margin-top: -214px;
      margin-left: -119px;
      background-color: #fff;
      .com-img{
        background: no-repeat center;
        background-size: cover;
      }
      .inner{
        width: 248px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .company-content {
          margin:10px auto;
          padding: 0 10px;
          word-wrap: break-word;
          font-size: 14px;
          .com-item {
            display: flex;
          }
          p{
            text-indent: 0px;
          }
        }
      }
    }
  }
</style>

<style lang="less">
  .inner{
    .el-carousel__button{
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
    .is-active{
      .el-carousel__button{
        background-color: #6AE1BC;
      }
    }
  }

</style>

<template :is="view"
          transition="fade"
          transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/Publicnumber/' }">公众号列表</el-breadcrumb-item>
      <el-breadcrumb-item>公众号详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <el-row class="cust-wraper">
        <el-col :span="16">
          <div style="margin-bottom: 15px;">
            <span>名称：</span>
            <span>{{pubNumberInfo.name}}</span>
          </div>
          <el-row style="margin-bottom: 15px;">
            <el-col :span="12">
              <div>
                <span>联 系 人：</span>
                <span>{{pubNumberInfo.connectName}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <span>联系电话：</span>
              <span>{{pubNumberInfo.connectTel}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 15px;">
            <el-col :span="12">
              <div>
                <span>品牌商：</span>
                <span>{{pubNumberInfo.customerName}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <span>原始ID：</span>
              <span>{{pubNumberInfo.originId}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 15px;">
            <el-col :span="12">
              <div>
                <span>绑定人数：</span>
                <span>{{pubNumberInfo.bindCount}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <span>APP密钥：</span>
              <span>{{pubNumberInfo.appSecret}}</span>
            </el-col>
          </el-row>
       
          <div>
            <span>微信APP ID：</span>
            <span>{{pubNumberInfo.appId}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <span>绑定二维码：</span>
            <span class="qrticket" v-if="pubNumberInfo.qrticket">
              <img :src="API_ROOT+pubNumberInfo.qrticket">
            </span>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="publicBrand">
        <el-carousel :interval="4000" type="card" height="200px" style="width:800px;margin:0 auto;">
          <el-carousel-item v-for="item in imgList" :key="item">
            <div class="brandImg" v-lazy:background-image="item"></div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <div class="companyInfo">
        <div class="com-content">
          <div class="inner">
            <el-carousel trigger="click" height="300px" arrow="never">
              <el-carousel-item v-for="item in imgList" class="com-img" :style="{'background-image':'url('+API_ROOT+item+')'}" :key="item">
              </el-carousel-item>
            </el-carousel>
            <div class="company-content" style="text-align:left;">
              <div class="com-item">
                <div class="com-lt">1. </div>
                <div class="com-rt">请打开设备电源</div>
              </div>
              <div class="com-item">
                <div class="com-lt">2. </div>
                <div class="com-rt">长按设备上的功能键3秒以上</div>
              </div>
              <div class="com-item">
                <div class="com-lt">3. </div>
                <div class="com-rt">当设备屏幕上出现WI-FI图标或者闪烁时点击下一步</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import {searchPubNumberById} from '../../../services/customer';
  import {Notification} from 'element-ui';
  import {PAGE_SIZE} from '../../../config';
  import {mapGetters, mapActions} from "vuex";
  import {API_ROOT} from '../../../config';
  import moment from 'moment';

  export default{
    data(){
      return {
        pubNumberInfo: {},
        API_ROOT,
        imgList: [],
      }
    },
    components: {
    },
    created() {
      const id = this.$route.params.id;
      this.getInfo(id);
      // this.;
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      /**
       * 获取客户信息；
       */
      async getInfo(id) {
        const res = await searchPubNumberById(id);
        if (res && res.errorCode === 0 && res.data) {
          this.pubNumberInfo = res.data;
          if(res.data.imageUrls){
            this.imgList = res.data.imageUrls.split(',');
          }
        }
      }
    },
    watch:{

    }

  }

</script>
